import type { CurrencyCode } from 'domains/money/types'
import { useRifm } from 'rifm'
import { getReferenceMaxLength } from '../utils/reference'
import { nonAlphaNumeric } from '../utils/regex'

export interface PayeeReferenceRifmOptions
  extends Omit<Parameters<typeof useRifm>[0], 'format'> {
  currency: CurrencyCode
}

export const usePayeeReferenceRifm = (options: PayeeReferenceRifmOptions) => {
  const maxLength = getReferenceMaxLength(options.currency)

  return useRifm({
    ...options,
    format: (value) => value,
    accept: /.+/,
    replace: (value) => {
      return value.replace(nonAlphaNumeric, '').slice(0, maxLength)
    },
  })
}
