import { useTrackUIView } from 'domains/analytics/hooks'
import { FormattedMessage } from 'react-intl'
import { AnimatedSuccessIcon, Status } from 'salad/components'
import { Grid, HStack, VStack } from 'salad/primitives'
import { styled, theme } from 'salad/stitches'
import { ApronFooter, TryApronPromo, PaymentSummaryCard } from '../../../components'
import type { PaymentFlowValues } from '../types'

const LeftHalfBackground = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  backgroundColor: theme.colors['page-background'],
})

const RightHalfBackground = styled('div', {
  gridRow: 1,
  gridColumn: 2,
  backgroundColor: theme.colors['white'],
})

const ContentWrapper = styled(VStack, {
  placeSelf: 'center',
  maxWidth: '512px',
  gap: theme.space[32],
})

interface SuccessResultStepProps {
  values: PaymentFlowValues
}

export const SuccessResultStep = ({ values }: SuccessResultStepProps) => {
  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'success',
    },
  })

  return (
    // background halves stack
    <HStack
      css={{
        height: '100%',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <LeftHalfBackground />
      <RightHalfBackground
        css={{
          display: 'none',
          '@bp2': {
            display: 'block',
          },
        }}
      />
      <Grid
        css={{
          gridRow: 1,
          gridColumn: '1 / -1',
          gridAutoFlow: 'row',
          gridTemplateColumns: '1fr',
          gridAutoRows: `minmax(${theme.space[32]}, auto) auto auto`,
          justifySelf: 'center',
          height: '100%',
          width: '100%',
          maxWidth: '1360px',
          minHeight: 'auto',
          '& > *': {
            minHeight: 'auto',
          },
          '@bp2': {
            gridAutoFlow: 'column',
            gridTemplateColumns: '1fr 1fr',
          },
        }}
      >
        <ContentWrapper
          css={{
            gridRow: 2,
            gridColumn: 1,
            paddingBlockEnd: theme.space[24],
            '@bp2': {
              paddingInline: theme.space[32],
            },
          }}
        >
          <Status.Root>
            <Status.Icon>
              <AnimatedSuccessIcon />
            </Status.Icon>
            <Status.Title>
              <FormattedMessage
                id="get-paid.payment.success.header"
                defaultMessage="Ding! It’s done"
              />
            </Status.Title>
          </Status.Root>
          <PaymentSummaryCard
            paymentMethod={values.paymentMethod}
            payrun={values.payrun}
            beneficiaryCompany={values.beneficiaryCompany}
          />
        </ContentWrapper>

        <ContentWrapper
          p={32}
          css={{
            gridRow: 3,
            gridColumn: 1,
            '@bp2': {
              gridRow: 2,
              gridColumn: 2,
            },
            background: theme.colors.white,
            borderRadius: theme.radii[16],
          }}
        >
          <TryApronPromo />
        </ContentWrapper>

        <ApronFooter
          css={{
            gridColumn: 1,
            gridRow: 4,
            '@bp2': {
              gridColumn: 1,
              gridRow: 3,
            },
          }}
        />
      </Grid>
    </HStack>
  )
}
