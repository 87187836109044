import * as Currency from '@dinero.js/currencies'
import { useLocale } from 'kitchen/hooks/use-locale'
import { triggerChange } from 'kitchen/utils/helpers'
import { useRef, useMemo } from 'react'
import { useRifm } from 'rifm'
import type { ScaledMoney } from '../types'
import { moneyFromFloat } from '../utils'
import { useMoneyFormat, type MoneyFormatOptions } from './use-money-format'

export interface MoneyRifmOptions extends Pick<MoneyFormatOptions, 'signDisplay'> {
  ref: React.RefObject<HTMLInputElement | null>
  mode: 'view' | 'edit'
  value: ScaledMoney
  onChange: (amount: ScaledMoney) => void
}

export function useMoneyRifm({
  ref,
  mode,
  value,
  onChange,
  signDisplay,
}: MoneyRifmOptions) {
  const locale = useLocale()
  const currency = Currency[value.currency]
  const exponent = value.scale ?? currency.exponent

  const rawValueRef = useRef<string>('')
  const point = useMemo(() => (1.1).toLocaleString(locale).replace(/1/g, ''), [locale])
  const accept = useMemo(() => new RegExp(`[\\d${point}+-]`, 'g'), [point])
  const discard = useMemo(() => new RegExp(`[^\\d${point}+-]`, 'g'), [point])

  const formatter = useMoneyFormat({
    signDisplay,
    get minimumFractionDigits() {
      const rawValue = rawValueRef.current

      const pointIndex = rawValue.indexOf(point)
      const fraction =
        pointIndex === -1 ? 0 : Math.min(rawValue.length - 1 - pointIndex, exponent)

      return mode === 'edit' ? fraction : exponent
    },
    maximumFractionDigits: exponent,
  })

  const moneyInputFormat = (money: ScaledMoney) => {
    const rawValue = rawValueRef.current

    if (value.amount === 0 && !/\d/g.test(rawValue)) {
      return ''
    }

    if (mode === 'view') {
      return formatter.format(money)
    }

    if (rawValue === point || rawValue === '-') {
      return rawValue
    }

    const parts = formatter.formatToParts(money)
    const pointIndex = rawValue.indexOf(point)

    let skip = pointIndex === -1
    return parts.reduceRight((acc, part) => {
      if (!skip) {
        const fraction = rawValue.slice(pointIndex + 1).replace(acc, '')

        switch (part.type) {
          case 'integer':
            skip = true

            if (fraction.endsWith('0') || fraction === '') {
              return part.value + point + fraction + acc
            }

            return part.value + point + acc
          case 'fraction':
            skip = true

            if (fraction.endsWith('0')) {
              return part.value + fraction.slice(part.value.length, exponent) + acc
            }

            return part.value + acc
        }
      }

      return part.value + acc
    }, '')
  }

  const parse = (rawValue: string): number => {
    const parsed = Number.parseFloat(rawValue.replace(discard, '').replace(point, '.'))

    if (Number.isNaN(parsed)) {
      return 0
    }

    if (parsed > Number.MAX_SAFE_INTEGER) {
      const maxLength = String(Number.MAX_SAFE_INTEGER).length
      return Math.min(parse(String(parsed).slice(0, maxLength)), Number.MAX_SAFE_INTEGER)
    }

    return parsed
  }

  const format = (rawValue: string) => {
    rawValueRef.current = rawValue

    const money = moneyFromFloat({
      float: parse(rawValue),
      currency: value.currency,
      scale: value.scale,
    })

    return moneyInputFormat(money)
  }

  const handleChange = (rawValue: string) =>
    onChange(
      moneyFromFloat({
        float: parse(rawValue),
        currency: value.currency,
        scale: value.scale,
      })
    )

  const replace = (rawValue: string) => {
    const element = ref.current

    if (mode === 'view' || rawValue !== point || element === null) {
      return rawValue
    }

    let position = 0
    const formatted = formatter
      .formatToParts({ amount: 0, currency: value.currency, scale: value.scale })
      .reduce((acc, part) => {
        switch (part.type) {
          case 'integer':
            const joined = acc + part.value + point

            position = joined.length
            return joined
          default:
            return acc + part.value
        }
      }, '')

    if (position && typeof window !== 'undefined') {
      window.requestAnimationFrame(() => element.setSelectionRange(position, position))
    }

    return formatted
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === point) {
      return
    }

    switch (event.key) {
      case '.':
      case ',': {
        event.preventDefault()
        const { currentTarget } = event
        const value = currentTarget.value
        const length = currentTarget.value.length
        const start = currentTarget.selectionStart ?? length
        const end = currentTarget.selectionEnd ?? length

        triggerChange(
          currentTarget,
          `${value.slice(0, start)}${point}${value.slice(end)}`
        )

        requestAnimationFrame(() => {
          const position = currentTarget.value.indexOf(point) + 1
          currentTarget.setSelectionRange(position, position)
        })
      }
    }
  }

  const rifm = useRifm({
    accept,
    value: moneyInputFormat(value),
    format,
    replace,
    onChange: handleChange,
  })

  return {
    ...rifm,
    onKeyDown: handleKeyDown,
  }
}
