import { initRudderAnalytics } from 'domains/analytics/rudderstack'
import { useHandleVitePreloadError } from 'kitchen/hooks/use-handle-vite-preload-error'
import { Suspense } from 'react'
import { Delayed } from 'salad/components'
import { ErrorBoundary, LoadingStatus } from './components'
import { GetPaidProviders } from './get-paid-providers'
import { GetPaidRoutes } from './get-paid-routes'
import { globalStyles } from './global-styles'

if (['production'].includes(process.env.BUILD_MODE) && !process.env.PLAYWRIGHT) {
  initRudderAnalytics()
}

export function GetPaidApp() {
  useHandleVitePreloadError()
  globalStyles()
  return (
    <GetPaidProviders>
      <ErrorBoundary>
        <Suspense
          fallback={
            <Delayed delay={500}>
              <LoadingStatus />
            </Delayed>
          }
        >
          <GetPaidRoutes />
        </Suspense>
      </ErrorBoundary>
    </GetPaidProviders>
  )
}
