import { useBeneficiaryCompany } from 'domains/company/queries'
import { LOCAL_CURRENCY } from 'domains/payment/constants'
import { useCreateDraftGetPaidPayrun } from 'domains/payrun/queries'
import { StorageKey } from 'kitchen/constants'
import {
  generatePath,
  parseAsInteger,
  parseAsString,
  Route,
  Routes,
  useNavigate,
  useParams,
  useQueryState,
} from 'kitchen/router'
import { ErrorCode } from 'kitchen/types'
import { ExtendedResponseError } from 'kitchen/utils/error'
import * as formats from 'kitchen/utils/formats'
import { assert } from 'kitchen/utils/helpers'
import type { ReactNode } from 'react'
import { Suspense } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Status, Toast } from 'salad/components'
import * as Icons from 'salad/icons'
import { StatusLayout } from 'salad/layouts'
import { match } from 'ts-pattern'
import { LoadingStatus } from '../../components'
import { Match, Path } from '../../constants'
import { PaymentFlow } from './payment-flow'
import { EntryStep } from './steps'

export const PaymentFlowPage = () => {
  const [amount] = useQueryState('amount', parseAsInteger.withDefault(0))
  const [billReference] = useQueryState('reference', parseAsString.withDefault(''))
  const [email] = useQueryState('email')

  const toast = Toast.useContext()
  const intl = useIntl()
  const navigate = useNavigate()

  const { companyAliasName } = useParams<{ companyAliasName: string }>()
  assert(companyAliasName)

  const beneficiaryCompany = useBeneficiaryCompany({ companyAliasName })

  const createDraftPayrun = useCreateDraftGetPaidPayrun({
    onSuccess: ({ id }) => {
      navigate({
        pathname: generatePath(Path.COMPANY_ALIAS_PAYMENT, {
          companyAliasName,
          payrunId: id,
        }),
      })
    },
    onError: (_, variables) =>
      toast.show(
        <Toast.Root>
          <Toast.Title>
            <FormattedMessage
              id="common.something-went-wrong"
              defaultMessage="Something went wrong"
            />
          </Toast.Title>
          <Toast.Action
            altText={intl.formatMessage({
              id: 'common.try-again',
              defaultMessage: 'Try again',
            })}
            onClick={() => createDraftPayrun.mutate(variables)}
          >
            <FormattedMessage id="common.try-again" defaultMessage="Try again" />
          </Toast.Action>
        </Toast.Root>
      ),
  })

  return match(beneficiaryCompany)
    .returnType<ReactNode>()
    .with({ status: 'error' }, ({ error }) => {
      return (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <Status.Root>
              <Status.Icon>
                <Icons.S64.Failure />
              </Status.Icon>

              {error instanceof ExtendedResponseError &&
                match(error.meta.errorCode)
                  .with(ErrorCode.NOT_FOUND, () => (
                    <>
                      <Status.Title>
                        <FormattedMessage
                          id="get-paid.payment.entry.invalid-link-error.title"
                          defaultMessage="Company not found"
                        />
                      </Status.Title>
                      <Status.Description>
                        <FormattedMessage
                          id="get-paid.payment.entry.invalid-link-error.description"
                          defaultMessage="Please check the link is valid."
                        />
                      </Status.Description>
                    </>
                  ))
                  .otherwise(() => (
                    <>
                      <Status.Title>
                        <FormattedMessage
                          id="common.something-went-wrong"
                          defaultMessage="Something went wrong"
                        />
                      </Status.Title>
                      {process.env.BUILD_MODE === 'development' && (
                        <Status.Description>{error.toString()}</Status.Description>
                      )}
                    </>
                  ))}
            </Status.Root>
          </StatusLayout.Content>
        </StatusLayout.Root>
      )
    })
    .with({ status: 'loading' }, () => <LoadingStatus />)
    .with({ status: 'success' }, (beneficiaryCompany) => (
      <Routes>
        <Route
          index
          element={
            <EntryStep
              values={{
                amount: { amount, currency: LOCAL_CURRENCY },
                beneficiaryCompany: beneficiaryCompany.data,
                billReference: formats.payeeReference(billReference, LOCAL_CURRENCY),
              }}
              onSubmit={(values) => {
                createDraftPayrun.mutate({
                  item: {
                    amount: values.amount,
                  },
                  reference: values.billReference,
                  companyId: values.beneficiaryCompany.id,
                })
              }}
              submitting={createDraftPayrun.isLoading}
            />
          }
        />
        <Route
          path={Match.PAYMENT_PAYRUN_ID}
          element={
            <Suspense fallback={<LoadingStatus />}>
              <PaymentFlow
                beneficiaryCompany={beneficiaryCompany.data}
                email={
                  email ?? (localStorage.getItem(StorageKey.GET_PAID_PAYER_EMAIL) || '')
                }
              />
            </Suspense>
          }
        />
      </Routes>
    ))
    .exhaustive()
}
